import { hideNull } from './hideNull'

export const skuToDescription = sku => {
  // Make Model Memory Colour - Carrier - Kit - SIM/ID/Region lock
  // The lock property can be '' or null.
  let locks = [
    sku.sim_lock === '' || sku.sim_lock === null ? null : 'SIM',
    sku.id_lock === '' || sku.id_lock === null ? null : 'ID',
    sku.region_lock === '' || sku.region_lock === null ? null : 'Region'
  ].filter(f => f !== null).join('/')
  if (locks === '') { locks = 'No' }
  return `${hideNull(sku.make)} ${hideNull(sku.model)} ${hideNull(sku.memory)} ${hideNull(sku.colour)} - ${hideNull(sku.carrier)} - ${hideNull(sku.kit)} - ${locks} lock`
}
